export default {
    // PersonalInfo
    name: '[a-zA-ZÀ-ž]{2,}',
    surname: '[a-zA-ZÀ-ž]{2,}',
    // nin: '[0-9/]{10,11}',
    email: '^[a-zA-Z0-9_.+-]+[a-zA-Z0-9_-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9]+(\\.[a-zA-Z0-9]+)?$',
    notEmpty: '^.{1,}$',
    password: '^[a-zA-Z0-9.]{5,}$', // matches a rule in ChangePasswordType
    phoneNumber: '^[679][0-9]{2} [0-9]{3} [0-9]{3}$',
    // accountNumber: '[0-9-]{4,17}',
    // idCardNumber: '^([0-9]{9,11}|[a-zA-Z]{2}[0-9]{6})$',

    // Address
    city: '[a-zA-ZÀ-ž]{2,}',
    street: '[a-zA-ZÀ-ž]{2,}',
    streetNumber: '[0-9a-zA-Z]{1,}',

    // Employer
    employer: '^[a-zA-ZÀ-ž0-9]{1}.{1,}',
    employerPosition: '[a-zA-ZÀ-ž]{2,}',
    employerContractEnd: '((?:20)[0-9][0-9])-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])',
    selfEmployerIco: '[0-9]{8}',

    // HouseHold
    householdPersons: '[1-9]{1}',
    householdPersonsWithIncome: '[1-9]{1}',

    income: '^[1-9]{1}[0-9]{2,5}$',
    costs: '^[1-9]{1}[0-9]{2,5}$',

    // Other
    promoCode: '[0-9a-zA-Z]{4,}',
}
