import $ from 'jquery'
import _ from 'lodash'

import { selectors } from '../selectors'
import { $clearElement, $enableElement, $readonlyElement, elementExists, $elementHasVal, $showElement, $hideElement } from '../shared'
import { $feedbackCrossAdd, $feedbackCrossRemove, $resetFeedbacks, ICON_CLASS } from '../validation/helpers'
import { promoCodeRequest } from '../ajaxCalls'

// TODO make changes in calculator-table with
// https://www.npmjs.com/package/countup.js ??
// It should look cool :-)

const SELECTORS = [selectors.inputs.homePagePromoCodeOI, selectors.inputs.homePagePromoCodeTI, selectors.inputs.registrationPromoCode, selectors.inputs.loanPromoCode]

export const DATA_ATTR_PROMOSALE = 'data-promocode-sale'
export const EVENT_PROMOSALE_CHANGE = 'data-promocode-sale-change'

export const init = () => {
    _.map(SELECTORS, (selector) => initFor(selector))
}

const initFor = (selector) => {
    if (!elementExists(selector)) {
        return
    }

    const $input = $(selector)
    const $submit = $input.parents('label').find('.promocode-submit')
    $hideElement($submit)

    $input.on('keyup', () => ($elementHasVal($input) ? $showElement($submit) : $hideElement($submit)))
    $submit.on('click', () => promoCodeRequest($input, $submit))

    $elementHasVal($input) && promoCodeRequest($input, $submit)
}

// ------------------------------------------------------

export const processPromoSaleChange = ($input, promoSale, $submit) => {
    updateDataAttr($input, promoSale)

    promoSale && lockInput($input, $submit)
    !promoSale && unLockInput($input, $submit)
}

export const getPromoSaleAttr = () => {
    return _.reduce(SELECTORS, (accum, selector) => $(selector).attr(DATA_ATTR_PROMOSALE) ?? accum, 0)
}

const updateDataAttr = ($input, promoSale) => {
    $input.attr(DATA_ATTR_PROMOSALE, promoSale)
    $(document).trigger(EVENT_PROMOSALE_CHANGE, promoSale) // Catched in rangesliders.js
}

// ------------------------------------------------------

const lockInput = ($input, $submit) => {
    $readonlyElement($input) // Input
    $feedbackCrossAdd($input) // Feedbacks
    addListener($input, $submit) // Listener
}

const unLockInput = ($input, $submit) => {
    $enableElement($input) // Input
    $feedbackCrossRemove($input) // Feedbacks
    $resetFeedbacks($input) // [!Redesign] remove "Vaše sleva: xx%"
    $showElement($submit)
}

// ------------------------------------------------------

const addListener = ($input, $submit) => {
    $input.parent().on('click', ICON_CLASS._BASE, () => {
        processPromoSaleChange($input, 0.0, $submit)
        $clearElement($input) // Remove .val()
    })
}
